<template>
  <div class="order-box">
    <van-nav-bar
      title="挪库记录"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell-group border >
      <van-cell title="挪库时间" :value="content.createTime"  />
      <van-cell title="操作人" :value="content.userName"  />
      <van-cell title="所属仓库" :value="content.storeHouseName"  />
      <van-cell title="挪入货架" :value="content.storeShelvesCode" />
    </van-cell-group>
    <div style="height: 25px"></div>
    <div class="list">
      <div class="header">
        <div class="left">库存码</div>
        <div class="right">原货架</div>
      </div>
      <div class="content" v-for="(item,index) in list" :key="index" >
        <div class="left">{{item.storeNo}}</div>
        <div class="right">{{item.oldShelvesCode}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getMoveGoodsById} from '../../service/inventory'
import navTop from '@/components/NavTop';
import {Toast} from "vant";
import {getMoveGoodsByPage} from "@/service/inventory";
export default {
  data(){
    return{
      shelfCode:'请扫码货架码',
      list:[],
      storeNos:[],//库存编码
      active:0,
      loading:false,
      finished: true,
      content:{}
    }
  },
  async mounted() {
    this.content = JSON.parse(localStorage.getItem('moveRecord'));
    this.getMoveDetailById({moveId:this.$route.query.moveId});
  },
  methods:{
    onClickLeft(){
      this.$router.go(-1);
    },
    async getMoveDetailById(param){
      const res = await getMoveGoodsById(param);
      if(res.code===0){
        this.list=res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-cell__title{
  font-size: 24px;
}
/deep/ .van-cell__value{
  font-size: 24px;
}
@import '../../common/style/mixin';
.tabs{
  margin-top: 20px;
}
.list{
  .header{
    font-size: 26px;
    font-weight: 650;
     margin: 0 30px;
     display: flex;
     flex-flow:row nowrap;
     background: #ececec;
     color: #3D69E1;
     height: 70px;
     align-items: center;
      .left{
        width: 60%;
      }
      .right{
        text-align: right;
        flex: 1;
      }
  }
  .content{
    margin: 0 30px;
    display: flex;
    flex-flow:row nowrap;
    height: 120px;
    align-items: center;
    font-size: 24px;
    border-bottom: 1px solid #ececec;
    .left{
      width: 60%;
    }
    .right{
      text-align: right;
      flex: 1;
    }
  }
}

</style>
