import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/index',
    meta:{title:'工作台'},

  },
  {
    path: '/login',
    name: 'login',
    meta: {
      index: 2
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/user/login.vue'),
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      index: 1,
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
  },
  {
    path: '/ex-warehouse',
    name: 'ex-warehouse',
    meta: {
      index: 1
    },
    component: () => import(/* webpackChunkName: "cart" */ '../views/warehouse/ex-warehouse.vue'),
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    meta: {
      index: 1
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/warehouse.vue'),

  },
  {
    path: '/inventory-list',
    name: 'inventory-list',
    meta: {
      index: 2
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/inventory-list.vue'),

  },
  {
    path: '/inventory',
    name: 'inventory',
    meta: {
      index: 3
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/inventory.vue'),
  },{
    path: '/inventory-history',
    name: 'inventory-history',
    meta: {
      index: 3
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/inventory-history.vue'),
  },
  {
    path: '/move-inventory',
    name: 'move-inventory',
    meta: {
      index: 2
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/move-inventory.vue'),

  },
  {
    path: '/move-history',
    name: 'move-history',
    meta: {
      index: 4
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/move-history.vue'),

  },
  {
    path: '/change-house',
    name: 'change-house',
    meta: {
      index: 1
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/home/change-house.vue'),

  },{
    path: '/user',
    name: 'user',
    meta: {
      index: 1
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/user/user.vue'),
  },
  {
    path: '/success',
    name: 'success',
    meta: {
      index: 1
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/success.vue'),
  },
  {
    path: '/failure',
    name: 'failure',
    meta: {
      index: 1
    },
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/failure.vue'),
  }]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
