<template>
  <div class="order-box">
    <van-nav-bar
      title="盘库"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-tabs type="card" sticky color="#3D69E1" class="tabs" v-model="active" @click="tabClick">
      <van-tab title="盘库"></van-tab>
      <van-tab title="盘库记录"></van-tab>
    </van-tabs>
    <div v-if="active===0">
      <div class="content">
        <ul>
          <div  class="title">按仓库</div>
          <li v-for="(item,index) in stockList" :key="index" @click="jump(1,item)">
              <div class="left">
                <img src="../../assets/warehouse.png"/>
              </div>
              <div class="middle">
                <p>{{item.text}}</p>
                <p>当前库存数 {{item.value}}</p>
              </div>
              <div class="right">
                <p>去盘库</p>
                <van-icon name="arrow" />
              </div>
          </li>
        </ul>
        <ul>
          <div  class="title">按货架</div>
          <li v-for="(item,index) in shelvesStockList" :key="index" @click="jump(2,item)">
            <div class="left">
              <img src="../../assets/shinshop.png"/>
            </div>
            <div class="middle">
              <p>{{item.shelvesCode+"-"+item.preCategoryName}}</p>
              <p>当前库存数 {{item.currentNum}}</p>
            </div>
            <div class="right">
              <p>去盘库</p>
              <van-icon name="arrow" />
            </div>
          </li>
        </ul>
        <ul>
          <div class="title">按品类</div>
          <li v-for="(item,index) in categoryStockList" :key="index"  @click="jump(3,item)">
            <div class="left">
              <img src="../../assets/category.png" />
            </div>
            <div class="middle">
              <p>{{item.text}}</p>
              <p>当前库存数 {{item.value}}</p>
            </div>
            <div class="right">
              <p>去盘库</p>
              <van-icon name="arrow" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="list">
          <ul>
            <li>
              <div class="block"  v-for="(item,index) in inventoryList" :key="index"  @click="jumpRecord(item)">
                <div class="left">
                  <img src="../../assets/shinshop.png"/>
                </div>
                <div class="middle">
                  <p>{{item.checkEventName}}</p>
                  <p>异常数目{{item.errorNum}}</p>
                  <p>操作人：{{item.checkUserName}}</p>
                  <p>操作时间：{{item.checkStartTime}}</p>
                </div>
                <div class="right">
                  <p>去查看</p>
                  <van-icon name="arrow" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {getStoreHouseStockNum,getStoreHouseShelvesStockNum,getStoreHouseCategoryStockNum,getGoodsCheckEventByPage,addGoodsCheckEvent} from '../../service/inventory'
import {Toast} from "vant";
import { setLocal, getLocal } from '@/utils/utils'
export default {
  data(){
    return{
      shelfCode:'请扫码货架码',
      list:[],
      storeNos:[],//库存编码
      active:0,
      loading:false,
      finished:false,
      stockList:[],
      shelvesStockList:[],
      categoryStockList:[],
      inventoryList:[],
      storeHouseId:getLocal('storeHouseId'),
      pageNum:1
    }
  },
  async mounted() {
    //测试数据
    //this.shelfCode ="HJXM1A02";
    //this.getDetail('CG2021121305-XMJFC1-BZ-035');
    let param ={
      storeHouseId:this.storeHouseId
    }
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    });
    this.getStoreHouseStockNum(param);
    this.getStoreHouseShelvesStockNum(param);
    this.getStoreHouseCategoryStockNum(param);
  },
  methods:{
    onClickLeft(){
      this.$router.push({ path: 'index'})
    },
    async getStoreHouseStockNum(param){
      const res = await getStoreHouseStockNum(param);
      if(res.code===0){
        this.stockList=res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getStoreHouseShelvesStockNum(param){
      const res = await getStoreHouseShelvesStockNum(param);
      if(res.code===0){
        this.shelvesStockList=res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getStoreHouseCategoryStockNum(param){
      const res = await getStoreHouseCategoryStockNum(param);
      Toast.clear();
      if(res.code===0){
        this.categoryStockList=res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getGoodsCheckEventByPage(param){
      const res = await getGoodsCheckEventByPage(param);
      this.loading =false;
      if(res.code==0){
        this.inventoryList=this.inventoryList.concat(res.data.list);
        if(res.data.lastPage){
           this.finished =true;
        }
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async addGoodsCheckEvent(param){
      const res = await addGoodsCheckEvent(param);
      Toast.clear();
      if(res.code===0){
        Toast("创建盘库事件成功");
        this.$router.push({path:'/inventory',query:{checkEventId:res.data.id}})
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    tabClick(name, title){
      if(this.active===1){
          this.loading =true;
          let param ={
            pageNum:1,
            numPerPage:5,
            storeHouseId:this.storeHouseId
          }
          this.inventoryList = [];
          this.getGoodsCheckEventByPage(param);
      }
    },
    onLoad() {
      this.loading =true;
      let param ={
        pageNum:++this.pageNum,
        numPerPage:5,
        storeHouseId:this.storeHouseId
      }
      this.getGoodsCheckEventByPage(param);
    },
    async jumpRecord(item){
      localStorage.setItem("inventoryRecord",JSON.stringify(item));
      this.$router.push({path:'/inventory-history',query:{checkEventId:item.id}})
    },
    async jump(num,item){
      let checkBusinessId,checkEventName,storeNum;
      if(num===2){
        storeNum = item.currentNum;
        checkBusinessId = item.id;
        checkEventName=item.shelvesCode+item.preCategoryName
      }else if(num===1){
        storeNum = item.value;
        checkBusinessId = this.storeHouseId;
        checkEventName=item.text
      }else{
        storeNum = item.value;
        checkBusinessId = item.id;
        checkEventName=item.text
      }
      let param ={
        moveType:num,
        storeNum:storeNum,
        checkBusinessId:checkBusinessId,
        storeHouseId:this.storeHouseId,
        checkEventName:checkEventName
      }
      this.addGoodsCheckEvent(param);
    }
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/deep/ .van-tab__text{
  font-size: 26px;
}
@import '../../common/style/mixin';
.tabs{
  margin-top: 20px;
}
.notarize{
  position: fixed;
  bottom: 80px;
  width: 534px;
  left: 50%;
  transform: translateX(-50%);
  button{
    height: 80px;
    border: 2px solid #3D69E1;
    border-radius: 40px;
    opacity: 1;
  }
  button:nth-of-type(1){
    width: 200px;
    background: #E7EBF8;
    color: #3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    width: 200px;
    background: #3D69E1;
    color: #FFFFFF;
  }
}
.list{
  li{
    .block{
      background: #fff;
      margin: 30px 30px;
      border: 1px solid #f1f1f1;
      display: flex;
      height: 240px;
      font-size: 24px;
      align-items: center;
      .left{
        flex:0 0 100px;
        text-align: center;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .middle{
        flex:0 0 460px;
      }
      .right{
        flex:1;
        color:#3D69E1;
        display: flex;
        align-items: center;
        p{
          font-size: 24px;
        }
      }
    }
  }
}
.content{
    margin:0 30px;
    h3{
      margin: 30px 0;
    }
    li{
      margin: 15px 0;
      border: 1px solid #f1f1f1;
      display: flex;
      height: 120px;
      align-items: center;
      .left{
        flex:0 0 100px;
        text-align: center;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .middle{
        flex:0 0 460px;
        p{
          font-size: 24px;
        }
      }
      .right{
        flex:1;
        color:#3D69E1;
        font-size: 24px;
        display: flex;
        align-items: center;
        p{
          font-size: 24px;
        }
      }
    }
}
.title{
  font-size: 28px;
  font-weight: 650;
}
.title:first-child{
  margin-top: 15px;
}
</style>
