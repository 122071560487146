

<template>
  <div style="background: #fff;">
    <nav-top :title="storeHouseName" @left="change" showHouse></nav-top>
    <div class="content">
      <div class="content-top">
        <div class="title">
          待办事件
        </div>
        <div class="content-center">
          <div>
            <p>
              <span>{{storageCount.putNum}}</span>件
            </p>
            <p>
              待入库
            </p>
          </div>
          <div>
            <p>
              <span>{{storageCount.outNum}}</span>单
            </p>
            <p>
              待出库
            </p>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <div class="title">
            快捷入口
        </div>
        <div class="operate">
          <div @click="skip">
            <img :src="storage">
            <p>入库</p>
          </div>
          <div @click="skipTwo">
            <img :src="outStorage">
            <p>出库</p>
          </div>
          <div @click="skipThree">
            <img :src="stocktaking">
            <p>盘库</p>
          </div>
          <div @click="skipFour">
            <img :src="noahLibrary">
            <p>挪库</p>
          </div>
        </div>
      </div>
    </div>
    <nav-bar></nav-bar>
  </div>
</template>

<script>
import { getGoodsStorageCount,getStoreHouseByUserId } from '../../service/index'
import navBar from '@/components/NavBar'
import navTop from '@/components/NavTop'
import { setLocal, getLocal } from '@/utils/utils'
import {Toast} from "vant";
import {getUserInfo} from "@/service/user";

export default {
  name: 'home',
  data() {
    return {
      outStorage:require('@/assets/out-storage.png'),
      storage:require('@/assets/Storage.png'),
      noahLibrary:require('@/assets/noah-library.png'),
      stocktaking:require('@/assets/stocktaking.png'),
      storageCount:{},
      storeHouseName:''
    }
  },
  components: {
    navBar,
    navTop
  },
  async mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    });
    if(getLocal('storeHouseId')){
      this.storeHouseId = getLocal('storeHouseId');
      this.storeHouseName =  getLocal('storeHouseName');
    }else{
      let param={
        userId:getLocal('userId')
      }
      const res= await getStoreHouseByUserId(param);
      if(res.code==0&&res.data.length!==0){
        setLocal('storeHouseId',res.data.on[0].id);
        setLocal('storeHouseName',res.data.on[0].storeHouseName);
        this.storeHouseId = res.data.on[0].id;
        this.storeHouseName = res.data.on[0].storeHouseName
      }else{
        Toast({message:res.message,duration:3000})
      }
    }
    const { data } = await getGoodsStorageCount();
    this.storageCount = data;
    Toast.clear();
  },
  methods: {
    change(){
      this.$router.push({path:'/change-house'})
    },
    async getStoreHouseByUserId(param){
      const res = await getStoreHouseByUserId(param);
      if(res.code===0){
        this.list =res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    skip(){
      this.$router.push({path:'/warehouse'})
    },
    skipTwo(){
      this.$router.push({path:'/ex-warehouse'})
    },
    skipThree(){
      this.$router.push({path:'/inventory-list'})
    },
    skipFour(){
      this.$router.push({path:'/move-inventory'})
    }
  }
}
</script>

<style lang="less" scoped >
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  @import '../../common/style/mixin';
  .content{
    padding: 24px 50px 0 40px;
    .content-top{
      width: 100%;
      height: 290px;
      background: #F8F8F8;
      border: 2px solid #FFFFFF;
      opacity: 1;
      border-radius: 0px;
      padding-top: 18px;
      .content-center{
        display: flex;
        text-align: center;
        height: calc(100% - 40px);
        div:nth-of-type(1){
          p{
            border-right: 2px solid #F4F4F4;
          }
        }
        &>div{
          width: 50%;
          padding-top: 10%;
          span{
            font-size: 36px;
            font-family: Yu Gothic UI;
            font-weight: 500;
            color: #000000;
            opacity: 1;
            margin-right: 5px;
          }
          p:nth-of-type(2){
            height: 32px;
            font-size: 24px;
            font-family: Yu Gothic UI;
            font-weight: 400;
            color: #5C5E62;
            opacity: 1;
          }
        }
      }
    }
    .content-bottom{
      margin-top: 20px;
      background: #FFFFFF;
      padding-top: 18px;
      min-height: 700px;
      .operate{
        margin-top: 30px;
        display: flex;
        padding-left: 14px;
        &>div{
          margin-right: 60px;
          text-align: center;
          img{
            width: 100px;
            height: 100px;
            font-size: 0;
          }
          p{
            font-size: 24px;
            font-family: Yu Gothic UI;
            font-weight: bold;
            color: #393C41;
            opacity: 1;
          }
        }
      }
    }
    .title{
      height: 40px;
      font-size: 28px;
      font-family: Yu Gothic UI;
      font-weight: bold;
      line-height: 40px;
      color: #000000;
      opacity: 1;
      padding-left: 24px;
    }
  }
</style>
