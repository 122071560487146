
import request from '../utils/request'

export function getHome(params) {
  return request.get('/index-infos');
}

export function getGoodsStorageCount(params) {
  return request.post('/storage/goodsCtrl/home/getGoodsStorageCount',params)
}

export function getGoodsCategoryList(params) {
  return request.post('/pub/goodsCtrl/home/getGoodsCategoryList',params)
}

export function getGoodsSkuCategoryList(params) {
  return request.post('/pub/goodsCtrl/home/getGoodsSkuCategoryList',params)
}

export function getStoreHouseByUserId(params) {
  return request.post('/storage/goodsCtrl/home/getStoreHouseByUserId',params)
}




