

<template>
  <div class="user-new">
    <nav-top :title="'我的'"></nav-top>
    <div class="content">
      <div class="content-img">
        <img :src="portrait">
      </div>
      <div class="message">
        <p>{{user.name}}</p>
        <p>{{user.loginName}}</p>
        <p>{{user.companyName}}</p>
      </div>
    </div>
    <div class="notarize">
      <button @click="logOut">退出账号</button>
    </div>
    <nav-bar></nav-bar>
  </div>

</template>

<script>
import navBar from '@/components/NavBar'
import navTop from '@/components/NavTop'
import { getUserInfo } from '../../service/user'
import { Toast } from 'vant'
import {setLocal} from "../../utils/utils";
export default {
  components: {
    navBar,
    navTop
  },
  data() {
    return {
      user: {},
      portrait:require('@/assets/portrait.png')
    }
  },
  async mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    });
    const { data } = await getUserInfo()
    this.user = data;
    Toast.clear();
  },
  methods: {
    logOut(){
      localStorage.clear();
      this.$router.push({path:'/login'})
    }
  }
}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import '../../common/style/mixin';
  .user-new{
    background: #F4F4F4;
  }
  .content{
    padding: 50px 60px 50px 54px;
    background: #fff;
    display: flex;
    .content-img{
      margin-right: 32px;
      img{
        width: 126px;
        height: 126px;
      }
    }
    .message{
      p{
        font-size: 28px;
        font-family: Yu Gothic UI;
        font-weight: bold;
        color: #000000;
        opacity: 1;
      }
      p:nth-of-type(3){
        font-size: 28px;
        font-family: Yu Gothic UI;
        font-weight: 400;
      }
    }

  }
  .notarize{
    position: fixed;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
    button{
      width: 400px;
      height: 80px;
      background: #B74134;
      border: 2px solid #B74134;
      opacity: 1;
      color: #FFFFFF;
      border-radius: 50px;
    }
  }

</style>
