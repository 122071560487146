
import request from '../utils/request'

export function wxScan(params) {
  return request({
    url:'/wx/scan',
    method:'post',
    data:params,
    dataType:'json'
  })
}


export function submitPutStorage(params) { //入库
  return request.post('/storage/goodsCtrl/home/submitPutStorage', params);
}


export function submitOutStorage(params) { //出库
  return request.post('/storage/goodsCtrl/home/submitOutStorage', params);
}


export function getGoodsSkuDetailByStoreNo(params) { //根据库存编码获取商品详情
  return request.post('/storage/goodsCtrl/home/getGoodsSkuDetailByStoreNo', params);
}


export function getOrderDetailInfoByNo(params) {//根据订单编号获取订单详情
  return request.post('/storage/orderCtrl/home/getOrderDetailInfoByNo', params);
}
