<template>
  <div class="order-box">
    <van-nav-bar
      title="仓库切换"
      right-text="取消"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div >
      <div class="content">
        <ul v-if="list.length!==0">
          <h3>启用中</h3>
          <li v-for="(item,index) in list" :key="index" @click="jump(item)">
              <div class="left">
                <img src="../../assets/warehouse.png"/>
              </div>
              <div class="middle">
                <p>{{item.storeHouseName}}</p>
                <p>{{item.locationStr}}</p>
              </div>
              <div class="right">
                盘它
                <van-icon name="arrow" />
              </div>
          </li>
        </ul>
        <ul v-if="listOff.length!==0">
          <h3>已停用</h3>
          <li v-for="(item,index) in listOff" :key="index">
            <div class="left">
              <img src="../../assets/warehouse-off.png"/>
            </div>
            <div class="middle" >
              <p style="color:#bfbfbf">{{item.storeHouseName}}</p>
              <p style="color:#bfbfbf">{{item.locationStr}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {getStoreHouseByUserId} from '../../service/inventory'
import { setLocal, getLocal } from '@/utils/utils'
import {Toast} from "vant";
export default {
  data(){
    return{
      list:[],
      listOff:[]
    }
  },
  async mounted() {
    let param={
      userId:getLocal('userId')
    }
    this.getStoreHouseByUserId(param);
  },
  methods:{
    async getStoreHouseByUserId(param){
      const res = await getStoreHouseByUserId(param);
      if(res.code===0){
        this.list =res.data.on;
        this.listOff =res.data.off;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    jump(item){
      setLocal('storeHouseId',item.id);
      setLocal('storeHouseName',item.storeHouseName);
      this.$router.push({ path: 'index'})
    },
    onClickLeft(){
      this.$router.push({ path: 'index'})
    },
    onClickRight(){
      this.$router.push({ path: 'index'})
    }
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import '../../common/style/mixin';
.tabs{
  margin-top: 20px;
}
.notarize{
  position: fixed;
  bottom: 160px;
  width: 534px;
  left: 50%;
  transform: translateX(-50%);
  button{
    height: 80px;
    border: 2px solid #3D69E1;
    border-radius: 40px;
    opacity: 1;
  }
  button:nth-of-type(1){
    width: 200px;
    background: #E7EBF8;
    color: #3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    width: 200px;
    background: #3D69E1;
    color: #FFFFFF;
  }
}
.list{
  li{
    .block{
      background: #fff;
      margin: 30px 30px;
      border: 1px solid #f1f1f1;
      display: flex;
      height: 240px;
      align-items: center;
      .left{
        flex:0 0 100px;
        text-align: center;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .middle{
        flex:0 0 460px;
      }
      .right{
        flex:1;
        color:#3D69E1
      }
    }
  }
}
.content{
    margin:0 30px;
    h3{
      margin: 30px 0;
    }
    li{
      margin: 15px 0;
      border: 1px solid #f1f1f1;
      display: flex;
      height: 120px;
      align-items: center;
      .left{
        flex:0 0 100px;
        text-align: center;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .middle{
        flex:0 0 460px;
      }
      .right{
        flex:1;
        color:#3D69E1
      }
    }
}

</style>
