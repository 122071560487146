

<template>
  <div class="login">
<!--    <s-header :name="'登录'" :leftArrow="false"></s-header>-->
    <div class="logo-box">
      <img class="logo" :src="logo" alt="">
      <div class="tip">仓储端</div>
    </div>
    <div class="prompt-message" v-if="show">
      <div class="prompt-box">
        <img class="prompt-img"  :src="prompt" alt="">
        <p class="message">{{ message }}</p>
      </div>
    </div>
    <div class="login-body login">
      <div class="phone">
        <label>手机号码</label>
        <input type="text" id="phone-input" v-model="username" >
      </div>
      <div class="password">
        <label>密码</label>
        <input type="password" id="password-input" v-model="password" right-icon="eye-o">
      </div>
      <div style="padding-top: 20px">
        <van-button round block type="info" color="#3D69E1" @click="onSubmit">登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { login, register, getUserInfo } from '../../service/user'
  import { setLocal, getLocal } from '@/utils/utils'
  import { Toast } from 'vant'
  export default {
    data() {
      return {
        logo:require('@/assets/logo.png'),
        phone:require('@/assets/login_phone.png'),
        prompt:require('@/assets/prompt.png'),
        username: '',
        password: '',
        type: 'login',
        verify: false,
        message:'',
        show:false,
      }
    },
    async mounted() {
      const  returnUrl  = this.$route.query.returnUrl;
      this.returnUrl =returnUrl
    },
    methods: {
      isTelephone: (phone) => {
        return !!(phone && /^0?1[3-9]\d{9}$/.test(phone));
      },
      async onSubmit() {
        if(!this.isTelephone(this.username)){
          this.message = '手机格式不正确!'
          this.show = true;
          return;
        }
        if(this.password===''){
          this.message = '请输入密码!'
          this.show = true;
          return;
        }
        const  res  = await login({
          "username": this.username,
          "password": this.password
        })
        if(res.code===0){
          this.show = false;
          setLocal('token', res.data.tokenId);
          let list = await getUserInfo();
          setLocal('userId',list.data.userId)
          setLocal('name',list.data.name)
          setLocal('companyName',list.data.companyName)
          if(!this.returnUrl){
            this.$router.push({ path: 'index'})
          }else{
            this.$router.push({ path: this.returnUrl})
          }

        }else{
          this.message = res.message
          this.show = true;
        }
      }
    },
  }
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
}
  .login {
    .logo-box{
      width: 100%;
      margin-top: 20%;
      text-align: center;
    }
    .logo {
      width: 160px;
      height:160px;
      display: block;
      margin: 0 auto;
    }
    .tip{
      margin-top: 10px;
      text-align: center;
      font-size: 34px;
      font-family: Yu Gothic UI;
      font-weight: bold;
      color: #4E5058;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
      letter-spacing: 5px;
      opacity: 1;
    }
    .prompt-message{
      margin-top: 36px;
      display: flex;
      padding: 0 82px;
      .prompt-box{
        padding: 34px 30px;
        background: #F5F5F5;
        border: 2px solid #F5F5F5;
        opacity: 1;
        border-radius: 10px;
        width: 100%;
        display: flex;
        vertical-align: middle;
        .prompt-img{
          width: 40px;
          height: 40px;
          margin-right: 30px;
          vertical-align: middle;
        }
        .message{
          vertical-align: middle;
          font-size: 28px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          color: #5C5D61;
          opacity: 1;
        }
      }

    }
    .login-body {
      margin-top: 60px;
      padding: 0 82px;
      .phone{
        color: #434343;
        width: 100%;
        margin-bottom: 20px;
        font-size: 30px;
        //width: 540px;
        label{
          margin-left: 30px;
          font-size: 28px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          line-height: 0px;
          color: #5C5D61;
          opacity: 1;
        }
        input{
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance:none ;
          outline: 0;
          width: 100%;
          height: 72px;
          box-sizing: border-box;
          border:0px solid #fff;
          border-radius: 72px;
          padding-left: 42px;
          background: #F5F5F5;
          margin-top: 12px;
        }
      }
      .password{
        color: #434343;
        position: relative;
        margin-top: 60px;
        margin-bottom: 20px;
        font-size: 30px;
        width: 100%;
        label{
          margin-left: 30px;
          font-size: 28px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          line-height: 0px;
          color: #5C5D61;
          opacity: 1;
        }
        input{
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance:none ;
          outline: 0;
          width: 100%;
          height: 72px;
          box-sizing: border-box;
          border:0px solid #fff;
          border-radius: 72px;
          padding-left: 42px;
          margin-top: 12px;
          background: #F5F5F5;
        }
      }
    }
    .login {
      .link-register {
        font-size: 28px;
        margin-bottom: 40px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .register {
      .link-login {
        font-size: 28px;
        margin-bottom: 40px;
        color: #5B8EDB;
        display: inline-block;
      }
    }
    .verify-bar-area {
      margin-top: 24px;
      .verify-left-bar {
        border-color: #1baeae;
      }
      .verify-move-block {
        background-color: #1baeae;
        color: #fff;
      }
    }
    .verify {
      >div {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      .cerify-code-panel {
        margin-top: 16px;
      }
      .verify-code {
        width: 40%!important;
        float: left!important;
      }
      .verify-code-area {
        float: left!important;
        width: 54%!important;
        margin-left: 14px!important;
        .varify-input-code {
          width: 90px;
          height: 38px!important;
          border: 1px solid #e9e9e9;
          padding-left: 10px;
          font-size: 16px;
        }
        .verify-change-area {
          line-height: 44px;
        }
      }
    }
  }
</style>
