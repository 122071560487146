
import request from '../utils/request'

export function getUserInfo() {
  return request.get('/storage/userCtrl/home/getUserInfoByToken');
}

export function login(params) {
  return request.post('/storage/doPasswordTimeDelayLogin', params);
}

export function logout() {
  return request.post('/storage/logout')
}


