<template>
  <div>
    <img class="left" :src="left">
    <div class="title">{{operation}}成功</div>
    <div class="succeed">
      <img :src="img">
    </div>
    <div class="button">
      <button @click="skip">返回工作台</button>
      <button @click="operate">继续{{operation}}</button>
    </div>
    <nav-bar ></nav-bar>
  </div>
</template>
<script>
import navBar from '@/components/NavBar'
export default {
  components:{
    navBar
  },
  data(){
    return{
      img:require('@/assets/succeed.png'),
      operation:'',
      left:require('@/assets/left.png'),
    }
  },
  mounted(){
    this.operation = this.$route.query.str
  },
  methods:{
    skip(){
      this.$router.push({path:'/'})
    },
    operate(){
      if (this.operation == '入库'){
        this.$router.push({path:'/warehouse'})
      }else if (this.operation == '盘库'){
        this.$router.push({path:'/inventory-list'})
      }else if(this.operation == '挪库'){
        this.$router.push({path:'/move-inventory'})
      }else{
        this.$router.push({path:'/ex-warehouse'})
      }
    }
  }
}
</script>
<style lang="less" scoped>
.left{
  position: fixed;
  top: 24px;
  left: 40px;
  width: 80px;
  height: 80px;
}
.title{
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  font-family: Yu Gothic;
  font-weight: bold;
  color: #FFFFFF;
  opacity: 1;
}
.succeed{
  height: 672px;
  background: #3D69E1;
  border: 2px solid #3D69E1;
  box-shadow: 0px 12px 14px rgba(0, 0, 0, 0.16);
  opacity: 1;
  display: flex;
  align-items: center;
  img{
    width: 278px;
    height: 264px;
    z-index: 99999;
    margin: 0 auto;
  }
}
.button{
  margin-top: 170px;
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  button{
    width: 200px;
    height: 80px;
    opacity: 1;
    border-radius: 40px;
    border: 2px solid #3D69E1;
  }
  button:nth-of-type(1){
    background: #E7EBF8;
    color:#3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    background: #3D69E1;
    color: #FFFFFF;
  }
}
</style>
