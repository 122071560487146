
import request from '../utils/request'

export function getStoreHouseStockNum(params) { //获取当前仓库库存数量
  return request.post('/storage/goodsCtrl/home/getStoreHouseStockNum', params);
}

export function getStoreHouseShelvesStockNum(params) { //获取当前仓库货架库存数量
  return request.post('/storage/goodsCtrl/home/getStoreHouseShelvesStockNum', params);
}

export function getStoreHouseCategoryStockNum(params) { //获取当前仓库品类库存数量
  return request.post('/storage/goodsCtrl/home/getStoreHouseCategoryStockNum', params);
}

export function addGoodsCheckEvent(params) {//新增盘库事件
  return request.post('/storage/goodsCtrl/home/addGoodsCheckEvent', params);
}

export function getSkuGoodsCheckDetail(params) {//获取盘库任务清单
  return request.post('/storage/goodsCtrl/home/getSkuGoodsCheckDetail', params);
}

export function updateGoodsCheckStatus(params) {//取消盘库
  return request.post('/storage/goodsCtrl/home/updateGoodsCheckStatus', params);
}

export function submitSkuGoodsStockByStoreNo(params) {//盘库扫描库存码
  return request.post('/storage/goodsCtrl/home/submitSkuGoodsStockByStoreNo', params);
}

export function submitGoodsCheck(params) {//完成盘库
  return request.post('/storage/goodsCtrl/home/submitGoodsCheck', params);
}

export function getGoodsCheckEventErrorByPage(params) {//根据盘库事件id获取异常
  return request.post('/storage/goodsCtrl/home/getGoodsCheckEventErrorByPage', params);
}

export function getLogSkuGoodsCheckError(params) {//获取异常库存列表
  return request.post('/storage/goodsCtrl/home/getLogSkuGoodsCheckError', params);
}

export function againSubmitPutStorage(params) {//处理异常扫库存码
  return request.post('/storage/goodsCtrl/home/againSubmitPutStorage', params);
}

export function getGoodsCheckEventByPage(params) {//分页获取盘库记录
  return request.post('/storage/goodsCtrl/home/getGoodsCheckEventByPage', params);
}

export function queryStockNoCheckInfo(params) {//获取盘库清单详情
  return request.post('/storage/goodsCtrl/home/queryStockNoCheckInfo', params);
}

export function getStoreDataOnline(params) {//在线获取库存数据
  return request.post('/storage/goodsCtrl/home/getStoreDataOnline', params);
}

export function getErrorStoreList(params) {//盘库获取异常数据
  return request.post('/storage/goodsCtrl/home/getErrorStoreList', params);
}

export function submitErrorDealResult(params) {//提交异常
  //return request.post('/storage/goodsCtrl/home/submitErrorDealResult', params)
  return request({
    url:'/storage/goodsCtrl/home/submitErrorDealResult',
    method:'post',
    data:params,
    dataType:'json'
  })
}

//挪库相关接口
export function getMoveGoodsByPage(params) {//分页获取挪库记录
  return request.post('/storage/goodsCtrl/home/getMoveGoodsByPage', params);
}

export function getMoveDetailById(params) {//获取挪库记录详情
  return request.post('/storage/goodsCtrl/home/getMoveDetailById', params);
}

export function getStoreHouseByUserId(params) {//根据用户id获取仓库列表
  return request.post('/storage/goodsCtrl/home/getStoreHouseByUserId', params);
}

export function getMoveGoodsById(params) {//根据挪库id获取挪库记录详情
  return request.post('/storage/goodsCtrl/home/getMoveGoodsById', params);
}

export function moveGoodsStorage(params) {//挪库
  return request.post('/storage/goodsCtrl/home/moveGoodsStorage', params);
}

