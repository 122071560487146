n<template>
  <div class="nav-bar">
    <ul class="nav-list">
      <router-link tag="li" class="nav-list-item active" to="index">
<!--        <i class="nbicon nblvsefenkaicankaoxianban-1"></i>-->
        <van-icon  name="goods-collect-o"  />
        <span>工作台</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="warehouse">
<!--        <i class="nbicon nbfenlei"></i>-->
        <van-icon  name="label-o"  />
        <span>入库</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="ex-warehouse">
        <van-icon  name="label-o"/>
        <span>出库</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="user">
<!--        <i class="nbicon nblvsefenkaicankaoxianban-"></i>-->
        <van-icon  name="user-o"  />
        <span>我的</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
  import { getLocal } from '../common/js/utils'
  export default {
    mounted() {
      const token = getLocal('token')
      const path = this.$route.path

    },
    data() {
      return {}
    },
    computed: {

    }
  }
</script>

<style lang="less" scoped >
    @import '../common/style/mixin';
    .nav-bar{
      background: #FFFFFF;
      border: 2px solid #F4F4F4;
      box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 5px 0;
      z-index: 1000;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      padding:20px 0;
      .nav-list {
        width: 100%;
        .fj();
        flex-direction: row;
        padding: 0;
        .nav-list-item {
          display: flex;
          flex: 1;
          flex-direction: column;
          text-align: center;
          color: #666;
          &.router-link-active {
            color: @primary;
          }
          i {
            text-align: center;
            font-size: 40px;
          }
          span{
            font-size: 24px;
          }
          .van-icon-shopping-cart-o {
            margin: 0 auto;
            margin-bottom: 2px;
          }
        }
      }
    }
</style>
